<template>
  <div class="login" id="login">
    <div class="welcomeDiv">
      <div>欢迎登录，</div>
      <div>智捷管理系统</div>
    </div>
    <div class="inputCode">
      <input type="text" v-model="loginInfo.username" placeholder="请输入账号"/>
    </div>
    <div class="inputCode">
      <input type="password" v-model="loginInfo.password" placeholder="请输入密码"/>
    </div>
    <div class="inputCode">
      <input type="text" v-model="loginInfo.code" maxlength="6" placeholder="验证码"/>
      <img v-if="imageCode" :src="imageCode" alt="codeImage" class="code-image" @click="getCodeImage">
      <img v-else src="@/assets/images/imgError.jpeg" alt="codeImage" class="code-image" @click="getCodeImage">
    </div>
    <div class="errorDiv">
      <div v-show="errorShow" class="errorInfo">{{ msg }}</div>
    </div>
    <div class="btnDiv">
      <div class="btn">
        <van-button class="loginBtn" :loading="loginLoading" loading-type="spinner" type="submit" @click="bindUser">登录</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import {getImgCode, login, getUserInfo, getCurrentUserNav, getOpenId, bindUser} from "@/getData/getData";
import {ACCESS_TOKEN, REFRESH_TOKEN, EXPIRE_TIME, USER, DICTIONARY, ALLUSERDICTIONARY, DEPTTREE, USERDICTIONARY, ROLEDICT} from '@/getData/mutation-types'
import {randomNum, clearAll, checkToken, localStorageSave, localStorageGet, getUrlKey, getCodeApi, isWeiXin} from "@/libs/util";
import { Button } from 'vant';
import { responseUtil } from "@/libs/brightUtil";

export default {
  components: {
    [Button.name]: Button,
  },
  name: "login",
  data() {
    return {
      errorShow: false,
      imageCode: undefined,
      randomId: randomNum(24, 16),
      code: '',
      openId: '',
      loginLoading: false,
      loginInfo: {
        username: '',
        password: '',
        code: '',
        key: '',
        grant_type: 'password'
      },
      msg: '手机号格式有误'
    }
  },
  async mounted() {
    let type = this.$route.params.type
    if (await checkToken() && !type) {
      this.$router.push({name: 'approveList'})
      return
    } else {
      clearAll()
    }
    if (isWeiXin()) {
      let code = getUrlKey("code");//获取url参数code
      if (!code) {
        getCodeApi("3cc1b0c9f5d8f595312d7e8453d0deca");
      }
      this.code = code
      if (this.code != null && this.code != undefined && this.code != '') {
        this.getOpenId()
      }
    }
    this.getCodeImage()
  },
  methods: {
    // 获取图形验证码
    getCodeImage () {
      getImgCode(this.randomId).then((res) => {
        return 'data:image/png;base64,' + btoa(new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), ''))
      }).then((res) => {
        this.imageCode = res
      }).catch((e) => {
        this.imageCode = undefined
        if (e.toString().indexOf('429') !== -1) {
          responseUtil.alertMsg('获取验证码过于频繁，请稍后再试')
        } else {
          responseUtil.alertMsg('获取图形验证码失败')
        }
      })
    },
    getOpenId() {
      var that = this
      const params = {}
      params.code = this.code
      params.type = 'xzzl'
      getOpenId(params).then(function (res) {
        responseUtil.dealResponse(that, res, () => {
          that.openId = res.data.openId
          that.weChatLogin()
        })
      })
    },
    bindUser() {
      var that = this
      if(this.checkInput()){
        return
      }
      const params = {}
      params.userName = this.loginInfo.username
      params.password= this.loginInfo.password
      params.webOpenId = this.openId
      that.loginLoading = true
      // 在微信内 绑定
      if(isWeiXin()) {
        bindUser(params).then(function (res) {
          if(res.code == 500) {
            that.getCodeImage()
            that.loginLoading = false
          }
          responseUtil.dealResponse(that, res, () => {
            that.login()
          })
        }).catch(() => {
          that.getCodeImage()
          that.loginLoading = false
        })
      } else {
        that.login()
      }
    },
    //登录
    login() {
      let that = this
      const loginParams = this.loginInfo
      loginParams.key = this.randomId
      login(loginParams).then(function (res) {
        if (res['access_token']) {
          that.cokiesAndSkip(res);
        } else {
          that.getCodeImage()
          that.loginLoading = false
          responseUtil.alertMsg('用户名或密码错误')
        }
      }).catch(() => {
        that.getCodeImage()
        that.loginLoading = false
      })
    },
    //登录
    weChatLogin() {
      let that = this
      const loginParams = JSON.parse(JSON.stringify(this.loginInfo))
      loginParams.username = this.openId
      loginParams.password = '123456'
      loginParams.login_type = 'front'
      loginParams.checkCode = 'false'
      login(loginParams).then(function (res) {
        if (res['access_token']) {
          that.cokiesAndSkip(res);
        } else {
          that.getCodeImage()
          that.loginLoading = false
        }
      }).catch(() => {
        that.getCodeImage()
        that.loginLoading = false
      })
    },
    checkInput(){
      let retType = false
      if(this.loginInfo.username == '' || this.loginInfo.username == undefined){
        retType = true
        responseUtil.alertMsg('请输入用户名')
        return retType
      }
      if(this.loginInfo.password == '' || this.loginInfo.password == undefined){
        retType = true
        responseUtil.alertMsg('请输入密码')
        return retType
      }
      if(this.loginInfo.code == '' || this.loginInfo.code == undefined){
        retType = true
        responseUtil.alertMsg('请输入验证码')
        return retType
      }
      return retType
    },
    //用户登录及跳转
    cokiesAndSkip(data) {
      localStorageSave(ACCESS_TOKEN, data['access_token'])
      localStorageSave(REFRESH_TOKEN, data['refresh_token'])
      const current = new Date()
      const expireTime = current.setTime(current.getTime() + 1000 * data['expires_in'])
      localStorageSave(EXPIRE_TIME, expireTime)
      this.getUserDetailInfo()
    },
    getUserDetailInfo(){
      var that = this
      getUserInfo().then(function (res) {
        if (res['principal']) {
          localStorageSave(USER, res['principal'])
          that.getCurrentUser()
        } else {
          that.getCodeImage()
          that.loginLoading = false
          responseUtil.alertMsg('获取用户信息异常')
        }
      }).catch(() => {
        that.getCodeImage()
        that.loginLoading = false
      })
    },
    getCurrentUser(){
      var that = this
      const user = localStorageGet(USER)
      getCurrentUserNav(user.username,'hrSystem').then(function (res) {
        if(res.code == 500) {
          that.getCodeImage()
          that.loginLoading = false
        }
        responseUtil.dealResponse(that, res, () => {
          localStorageSave(DICTIONARY, res.data.dict)
          localStorageSave(USERDICTIONARY, res.data.userDict)
          localStorageSave(ALLUSERDICTIONARY, res.data.allUserDict)
          localStorageSave(DEPTTREE, res.data.deptTree)
          localStorageSave(ROLEDICT, res.data.roleDict)
          that.getCodeImage()
          that.loginLoading = false
          window.location.href = window.location.origin + '/#/approveList'
          // that.$router.push({ name: 'approveList' })
        })
      }).catch(() => {
        that.getCodeImage()
        that.loginLoading = false
      })
    }
  }
}
</script>
<style scoped>

/* 输入框输入内容后背景色*/
.login {
  width: 100%;
  padding-top: 50px;
}

.indexImg {
  font-size: 15px;
  width: 1.3rem;
  height: 1.1rem;
}

.img_title {
  margin-top: 1.25rem;
  margin-left: 7.5%;
  width: 2rem;
  height: 1.5rem;
}

/*登录页面顶部文字*/
.welcomeDiv {
  margin-bottom: 1.2rem;
  margin-left: 7.5%;

}

.welcomeDiv div {
  font-size: 26px;
  color: #222222;
}

/*手机号码输入错误信息提示*/
.errorDiv {
  float: left;
  margin-left: 7.5%;
  height: 2rem;
  /*width: 8rem;*/
  /*margin-top: 1rem;*/
}

.errorInfo {
  padding-left: 0.15rem;
  padding-top: 0.15rem;
  padding-bottom: 0.2rem;
  margin: 0 auto;
  color: white;
  border-radius: 0.1rem;
  width: 2.5rem;
  background-color: black;
  opacity: 0.55;
  font-size: 12px;

}

/*属性placeholder样式*/
input::-webkit-input-placeholder {
  color: #D8D8D8;
  font-family: PingFangSC-Regular;
  font-size: 15px;
  text-align: left;
  transform: translateY(5%);
}

.otherLogin {
  width: 8rem;
  margin-left: 7.5%;
  /*margin-bottom: 3rem;*/
  margin-top: 0.3rem;
}

/*点击密码登录*/
.codeLogin {
  float: left;
  color: #ff5D3B;
  font-size: 13px;
  font-family: PingFangSC-Semibold;
  /*margin-bottom: 2rem;*/
}

/*忘记密码*/
.forgetPwd {
  float: right;
  color: steelblue;
  font-size: 13px;
  font-family: PingFangSC-Semibold;
  /*margin-bottom: 2rem;*/
}

.loginBtn {
  font-weight: bold;
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: white;
  width: 92%;
  margin-left: 4%;
  height: 1.2rem;
  margin-bottom: 0.3rem;
  border: 0;
  border-radius: 0.15rem; /*圆角*/
  background: linear-gradient(90deg, #1500FB 0%, #5400FB 47%, #6D00FB 99%);
}

.signUp {
  font-weight: bold;
  font-family: PingFangSC-Semibold;
  color: #ff5809;
  width: 92%;
  margin-left: 4%;
  height: 1.2rem;
  background-color: #f8f8f8;
  border: 0.03rem solid #ff5809;
  border-radius: 0.15rem; /*圆角*/
  font-size: 16px;
}

.btnDiv {
  margin-bottom: 1.5rem;
}

/*温馨提示样式*/
.point {
  margin-top: 1rem;
  margin-left: 3%;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  width: 94%;
  font-size: 12px;
  color: #999999;
  text-indent: 0.6rem; /*首行缩进*/
}

.point span {
  color: steelblue;
}

/*竖线样式*/
.line {
  padding-right: 0.15rem;
  padding-left: 0.15rem;
  color: #D8D8D8;
  font-size: 0.4111rem;
}

/*验证码输入框Div*/
.inputCode {
  height: 37.5px;
  background: #f8f8f8;
  width: 82%;
  border-bottom: 1PX solid #EFEFEF;
  margin: 20px auto;
  display: flex;
  align-items: center;
  overflow: hidden;
}

/*验证码输入框*/
.inputCode input {
  border: none;
  width: 90%;
  font-size: 16px;
  background: #f8f8f8;
  margin-left: 0.3rem;
  /*box-shadow: none;*/
}

.label {
  color: #ff5809;
  font-size: 16px;
  margin-left: 0.3rem
}

/*input{*/
/*    -webkit-box-shadow: 5px 5px 5px 1000px   #f8f8f8  inset !important;*/
/*}*/
</style>
